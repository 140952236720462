
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonButton, IonTextarea, IonInput, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import axios from 'axios';
import useDataService from "../dataservice";
import UserLink from '@/views/UserLink.vue';
import AudioLink from '@/views/AudioLink.vue';
import VideoLink from '@/views/VideoLink.vue';
import InstaLink from '@/views/InstaLink.vue';

export default {
	name: 'PostNew',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonButton,
		IonTextarea,
		IonInput,
		IonLabel, IonSelect, IonSelectOption,
		UserLink, AudioLink, VideoLink, InstaLink
	},
	methods: {
	},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const folder = 'Создать новую запись';
	const { token } = useDataService();

	const forms = {title:'',text:'',mood:'',music:'',want:'',categories:'',access:'all',commaccess:'all'};
	const formsText = ref("");
	
	const smilesArr = [
			['smile',':-)'],
			['sad',':-('],
			['wink',';-)'],
			['kiss',':-*'],
			['big-smile',':-D'],
			['surprised',':-O'],
			['tongue-sticking-out',':-P'],
			['angry','X-('],
			['devil',']:-)'],
			['angel','O:-)'],
			['cry',":'("],
			['upset',':-['],
			['confused',':-\\'],
			['undecided',':-|'],
			['thinking',':-?'],
			['cunning',';~)'],
			['tired','(:|'],
			['crazy','8-}'],
			['shhh',':-$'],
			['shocked','8-|'],
			['sun-glasses','B-)'],
			['turn-red',':^)'],
			['thumbs-up','=^B'],
			['thumbs-down','=,B']
		];
		
	const addSmile = function(event: any){
		const smlCode = event.currentTarget.getAttribute('data-smile');
		formsText.value += " " + smlCode + " ";
	};
	const addUserLink = function(e: any){
		formsText.value += "[user-" + e + "]";
	};
	const addImageLink = function(e: any){
		formsText.value += "[image-original-none-" + e + "]";
	};
	const addAudioLink = function(e: any){
		formsText.value += e;
	};
	const addVideoLink = function(e: any){
		formsText.value += e;
	};
	const addInstaLink = function(e: any){
		formsText.value += e;
	};
	const addTag =  function(tag: string){
		formsText.value += '[' + tag + '][/' + tag + ']';
	};
	
	const modalShow = ref(false);
	const uploadModalShow = ref(false);
	const imagelink = ref(['','original','none']);
	const imageupload = ref(['original','none']);
	const modalPicLink = function(){
		if(/^https?:\/\/.*\..*\/.*/.test(imagelink.value[0])){
			formsText.value += " [image-" + imagelink.value[1] + "-" + imagelink.value[2] + "-" + imagelink.value[0] + "]";
			modalShow.value = false;
			imagelink.value[0] = "";
		}
	};
	
	const uploadFiles = ref<any>(null);
	const formError = ref<string>('');
	const modalPicUpload = function(){
		formError.value = '';
		const formData = new FormData();
		for(let i=0;i<uploadFiles.value.length;i++){
			formData.append('image[]', uploadFiles.value[i]);
		}
		
		axios.post( 'https://beon.fun/api/v1/upload/images',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': 'Bearer ' + token.value,
				}
			}
		).then((response) => {
			console.log('SUCCESS!!');
			if(response.data.status === 'ok' && response.data.fileurls.length){
				response.data.fileurls.forEach((item: any) => {
					formsText.value += '[img-' + imageupload.value[0] + '-' + imageupload.value[1] + '-' + item + ']';
				});
				uploadModalShow.value = false;
			} else {
				formError.value = 'Ошибка при загрузке файла';
			}
		})
		.catch(function(){
			formError.value = 'Ошибка при загрузке файла';
		});
		
		uploadModalShow.value = false;
	};
	const filePickerUpdated = function(event: any){
		uploadFiles.value = event.target.files;
	}
	
	const sendPost = function(){
		if(forms.title.length > 3 && formsText.value.length > 3){
			forms.text = formsText.value;
			axios.post('https://beon.fun/api/v1' + route.path,forms,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then((response) => {
				if(response.data.status == 'ok'){
					router.replace('post/' + response.data.postid )
				}
			});
		}
	};

    return { folder, forms, formsText, sendPost, smilesArr, addSmile, addUserLink, addImageLink, addAudioLink, addVideoLink, addInstaLink,
		modalShow, uploadModalShow, imagelink, imageupload, modalPicLink, addTag, formError, filePickerUpdated, modalPicUpload, 
	}
  }
}
