<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
		<div id="container">
			<form @submit.prevent>
				
				<ion-item>
					<ion-label position="floating">Название</ion-label>
					<ion-input v-model="forms.title"></ion-input>
				</ion-item>
				<ion-item class="editor-bar editools">
					<span @click="addTag('B')" title="Жирный"><b>B</b></span>
					<span @click="addTag('I')" title="Курсив"><i>I</i></span>
					<span @click="addTag('U')" title="Подчеркнутый"><u>U</u></span>
					<span @click="addTag('S')" title="Зачеркнутый"><s>S</s></span>
					<span @click="addTag('H')" title="Подзаголовок"><b>H</b></span>
					<span @click="addTag('OFF')" title="Офф-топик">OFF</span>
					<span @click="addTag('JUSTIFY')" title="По ширине"><fa icon="align-justify" class="fas-icon"></fa></span>
					<span @click="addTag('CENTER')" title="По центру"><fa icon="align-center" class="fas-icon"></fa></span>
					<span @click="addTag('RIGHT')" title="По правому краю"><fa icon="align-right" class="fas-icon"></fa></span>
					<span v-if="false" @click="addTag('SPOILER')" title="Скрыть текст за ссылкой 'Подробнее...'">SPOILER</span>
					<span @click="modalShow = true"><fa icon="image" class="fas-icon"></fa></span>
					<span @click="uploadModalShow = true"><fa icon="cloud-upload-alt" class="fas-icon"></fa></span>
					<AudioLink @audio-add="addAudioLink"></AudioLink>
					<VideoLink @video-add="addVideoLink"></VideoLink>
					<InstaLink @insta-add="addInstaLink"></InstaLink>
					<UserLink @user-add="addUserLink"></UserLink>
					<span @click="formsText = ''" title="Очистить форму"><fa icon="trash-alt" class="fas-icon"></fa></span>
				</ion-item>
				<ion-item class="smiles-wrapper">
					<img class="smile-img" v-for="sml in smilesArr" :key="sml" :src="'//beon.fun/i/smiles/' + sml[0] + '.png'" :data-smile="sml[1]" @click="addSmile($event)">
				</ion-item>
				<ion-label>Текст</ion-label>
				<ion-item>
					<ion-textarea placeholder="Текст записи" v-model="formsText"></ion-textarea>
				</ion-item>
				<ion-item>
					<ion-input placeholder="Настроение" v-model="forms.mood"></ion-input>
				</ion-item>
				<ion-item>
					<ion-input placeholder="Играет музыка" v-model="forms.music"></ion-input>
				</ion-item>
				<ion-item>
					<ion-input placeholder="Хочется" v-model="forms.want"></ion-input>
				</ion-item>
				<ion-item>
					<ion-label>Доступ</ion-label>
					<ion-select placeholder="Доступ" v-model="forms.access">
						<ion-select-option value="all">Все</ion-select-option>
						<ion-select-option value="users">Пользователи</ion-select-option>
						<ion-select-option value="me">Только я</ion-select-option>
					</ion-select>
				</ion-item>
				<ion-item>
					<ion-label>Комментирование</ion-label>
					<ion-select placeholder="Комментирование" v-model="forms.commaccess">
						<ion-select-option value="all">Все</ion-select-option>
						<ion-select-option value="users">Пользователи</ion-select-option>
						<ion-select-option value="me">Только я</ion-select-option>
					</ion-select>
				</ion-item>
				<ion-item>
					<ion-input placeholder="Категории" v-model="forms.categories"></ion-input>
				</ion-item>
				
				<div>
					<ion-button expand="block" size="large" color="primary" @click="sendPost()">Создать запись</ion-button>
				</div>
			</form>	
		</div>
		
	<div v-bind:class="{'modal-fade': true, show: modalShow}">
		<div class="modal">
			<div>Добавить изображение по ссылке</div>
			<div><ion-textarea v-model="imagelink[0]" placeholder="http://"></ion-textarea></div>
			<ion-item>
				<ion-label>Размер</ion-label>
				<ion-select v-model="imagelink[1]" @ionChange="imagelink[1]= $event.target.value">
					<ion-select-option value="original">Оригинал</ion-select-option>
					<ion-select-option value="medium">320px</ion-select-option>
					<ion-select-option value="small">200px</ion-select-option>
				</ion-select>
			</ion-item>
			<ion-item>
				<ion-label>Выравнивание</ion-label>
				<ion-select v-model="imagelink[2]" @ionChange="imagelink[2]= $event.target.value">
					<ion-select-option value="none">Нет</ion-select-option>
					<ion-select-option value="left">Влево</ion-select-option>
					<ion-select-option value="right">Вправо</ion-select-option>
				</ion-select>
			</ion-item>
			<div>
				<ion-button color="medium" @click="modalShow = false">Отмена</ion-button><ion-button @click="modalPicLink">Прикрепить</ion-button>
			</div>
		</div>
	</div>
	
	<div v-bind:class="{'modal-fade': true, show: uploadModalShow}">
		<div class="modal">
			<div>Выгрузить изображения с телефона</div>
			<div class="form-error">{{formError}}</div>
			<div><ion-input type="file" name="photo" @change="filePickerUpdated" multiple></ion-input></div>
			<ion-item>
				<ion-label>Размер</ion-label>
				<ion-select v-model="imageupload[0]" @ionChange="imageupload[0]= $event.target.value">
					<ion-select-option value="original">Оригинал</ion-select-option>
					<ion-select-option value="medium">320px</ion-select-option>
					<ion-select-option value="small">200px</ion-select-option>
				</ion-select>
			</ion-item>
			<ion-item>
				<ion-label>Выравнивание</ion-label>
				<ion-select v-model="imageupload[1]" @ionChange="imageupload[1]= $event.target.value">
					<ion-select-option value="none">Нет</ion-select-option>
					<ion-select-option value="left">Влево</ion-select-option>
					<ion-select-option value="right">Вправо</ion-select-option>
				</ion-select>
			</ion-item>
			<div>
				<ion-button color="medium" @click="uploadModalShow = false">Отмена</ion-button><ion-button @click="modalPicUpload">Прикрепить</ion-button>
			</div>
		</div>
	</div>
    </ion-content>
  </ion-page>
  
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post {
	padding-bottom: 20px;
}
.post__title {
    font-weight: bold;
	font-size: 120%;
}
.post__date {
	color: #808080;
    font-size: 80%;
    margin-left: 1rem;
}
.post__body {
    display: flex;
}
.post__text {
    flex-grow: 1;
	word-break: break-word;
}
.post__author {
    text-align: center;
	margin-right: 1%;
	max-width: 120px;
}
.post__sign {
    font-size: 80%;
    color: #808080;
}
.smile-img {
	margin-right: 1rem;
}
.smiles-wrapper, .editor-bar {
	overflow-x: auto;
}

.modal-fade {
	position: fixed;
	top:-100%;
	width: 100%;
	height: 100%;
	background: #2c2c2ccc;
	z-index: 99;
	display: flex;
}
.modal-fade.show {
	top: 0;
}
.modal {
	margin: auto;
	background: white;
	width: 50%;
	min-width:320px;
	height: 50%;
	min-height: 320px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}
.modal > * {
	text-align:center;
	width:90%;
}
ion-textarea {
	height: 30vh;
	max-height: 300px;
}
</style>

<style>
.fas-icon, .editools span {
	height: 1.5rem;
	font-size: 1.5rem;
	margin-right: .5rem;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonButton, IonTextarea, IonInput, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import axios from 'axios';
import useDataService from "../dataservice";
import UserLink from '@/views/UserLink.vue';
import AudioLink from '@/views/AudioLink.vue';
import VideoLink from '@/views/VideoLink.vue';
import InstaLink from '@/views/InstaLink.vue';

export default {
	name: 'PostNew',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonButton,
		IonTextarea,
		IonInput,
		IonLabel, IonSelect, IonSelectOption,
		UserLink, AudioLink, VideoLink, InstaLink
	},
	methods: {
	},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const folder = 'Создать новую запись';
	const { token } = useDataService();

	const forms = {title:'',text:'',mood:'',music:'',want:'',categories:'',access:'all',commaccess:'all'};
	const formsText = ref("");
	
	const smilesArr = [
			['smile',':-)'],
			['sad',':-('],
			['wink',';-)'],
			['kiss',':-*'],
			['big-smile',':-D'],
			['surprised',':-O'],
			['tongue-sticking-out',':-P'],
			['angry','X-('],
			['devil',']:-)'],
			['angel','O:-)'],
			['cry',":'("],
			['upset',':-['],
			['confused',':-\\'],
			['undecided',':-|'],
			['thinking',':-?'],
			['cunning',';~)'],
			['tired','(:|'],
			['crazy','8-}'],
			['shhh',':-$'],
			['shocked','8-|'],
			['sun-glasses','B-)'],
			['turn-red',':^)'],
			['thumbs-up','=^B'],
			['thumbs-down','=,B']
		];
		
	const addSmile = function(event: any){
		const smlCode = event.currentTarget.getAttribute('data-smile');
		formsText.value += " " + smlCode + " ";
	};
	const addUserLink = function(e: any){
		formsText.value += "[user-" + e + "]";
	};
	const addImageLink = function(e: any){
		formsText.value += "[image-original-none-" + e + "]";
	};
	const addAudioLink = function(e: any){
		formsText.value += e;
	};
	const addVideoLink = function(e: any){
		formsText.value += e;
	};
	const addInstaLink = function(e: any){
		formsText.value += e;
	};
	const addTag =  function(tag: string){
		formsText.value += '[' + tag + '][/' + tag + ']';
	};
	
	const modalShow = ref(false);
	const uploadModalShow = ref(false);
	const imagelink = ref(['','original','none']);
	const imageupload = ref(['original','none']);
	const modalPicLink = function(){
		if(/^https?:\/\/.*\..*\/.*/.test(imagelink.value[0])){
			formsText.value += " [image-" + imagelink.value[1] + "-" + imagelink.value[2] + "-" + imagelink.value[0] + "]";
			modalShow.value = false;
			imagelink.value[0] = "";
		}
	};
	
	const uploadFiles = ref<any>(null);
	const formError = ref<string>('');
	const modalPicUpload = function(){
		formError.value = '';
		const formData = new FormData();
		for(let i=0;i<uploadFiles.value.length;i++){
			formData.append('image[]', uploadFiles.value[i]);
		}
		
		axios.post( 'https://beon.fun/api/v1/upload/images',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': 'Bearer ' + token.value,
				}
			}
		).then((response) => {
			console.log('SUCCESS!!');
			if(response.data.status === 'ok' && response.data.fileurls.length){
				response.data.fileurls.forEach((item: any) => {
					formsText.value += '[img-' + imageupload.value[0] + '-' + imageupload.value[1] + '-' + item + ']';
				});
				uploadModalShow.value = false;
			} else {
				formError.value = 'Ошибка при загрузке файла';
			}
		})
		.catch(function(){
			formError.value = 'Ошибка при загрузке файла';
		});
		
		uploadModalShow.value = false;
	};
	const filePickerUpdated = function(event: any){
		uploadFiles.value = event.target.files;
	}
	
	const sendPost = function(){
		if(forms.title.length > 3 && formsText.value.length > 3){
			forms.text = formsText.value;
			axios.post('https://beon.fun/api/v1' + route.path,forms,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then((response) => {
				if(response.data.status == 'ok'){
					router.replace('post/' + response.data.postid )
				}
			});
		}
	};

    return { folder, forms, formsText, sendPost, smilesArr, addSmile, addUserLink, addImageLink, addAudioLink, addVideoLink, addInstaLink,
		modalShow, uploadModalShow, imagelink, imageupload, modalPicLink, addTag, formError, filePickerUpdated, modalPicUpload, 
	}
  }
}
</script>